export default {
    // 系统名称
    productName: '豪胜三联-后台管理系统',
    baseUrl: {
        // 线上
        prod: 'https://car-shop.y7yun.com/api',
        // 本地
        dev: 'http://192.168.1.109:8001',
    },
}
