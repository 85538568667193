import { createApp } from 'vue'
import App from './App.vue'
import router from '@/router'
import '@/assets/styles/main.scss'
//
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
//
import store from './store';

const app = createApp(App)


//
app.use(ElementPlus);

app.use(router)
app.use(store)

app.mount('#app')
